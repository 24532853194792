import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.infra.project.deploy.new');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-infra-project-deploy-new',
template: templateSource || '',
    styles: [`

/* file: /opt/season-cloud-manager/project/main/build/src/app/portal.infra.project.deploy.new/view.scss */
/* src/app/portal.infra.project.deploy.new/view.scss: no such file or directory */`],
})
export class PortalInfraProjectDeployNewComponent implements OnInit {
    @Input() model: any = null;
    @Input() project: any = {};

    public step: any = 'step1';

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public loading: boolean = false;
    public list: any = [];

    public keyuptime: any = 0

    public search: any = {
        text: "",
        page: 1,
        dump: 40
    }

    public pagination: any = {
        end: -1,
        start: -1,
        current: 0
    }

    public async load(page: number = 1) {
        if (this.loading) return;
        this.loading = true;
        this.pagination.current = page;
        this.search.page = page;
        this.list = [];
        await this.service.render();

        const { code, data } = await wiz.call("search", this.search);

        if (code == 200) {
            let { rows, lastpage } = data;
            const startpage = Math.floor((page - 1) / 10) * 10 + 1;
            this.list = rows;
            this.pagination.start = startpage;
            this.pagination.end = lastpage;
        }

        this.loading = false;
        await this.service.render();
    }

    public async searchAction() {
        let keyuptime: any = new Date().getTime();
        this.keyuptime = keyuptime;
        await this.service.render(500);
        if (this.keyuptime != keyuptime) return;
        await this.load();
    }

    public selected: any;
    public deploy_name: any = "";

    public async select(item) {
        this.selected = item;
        this.step = 'step2';
        await this.service.render();
        await this.info();
    }

    public hosts: any = [];
    public ips: any = [];
    public deploy: any = [];

    public async info() {
        const { code, data } = await wiz.call("info", { id: this.project.id });
        this.hosts = data.hosts;
        this.ips = data.ips;
        this.deploy = data.deploy;

        for (let i = 0; i < this.selected.params.length; i++) {
            if (this.selected.params[i].type == 'hostname') {
                this.selected.params[i].value_basehost = '';
            }
            if (this.selected.params[i].type == 'ip') {
                this.selected.params[i].value = "";
            }
            if (this.selected.params[i].type == 'deploy') {
                this.selected.params[i].value = "";
            }
        }
        await this.service.render();
    }

    public async create() {
        if (!this.deploy_name)
            return await this.service.modal.error(`Input Deploy Name`, false, `Confirm`);

        await this.service.status.show("loading");
        let params = {};

        for (let i = 0; i < this.selected.params.length; i++) {
            let param = this.selected.params[i];
            if (param.type == 'hostname') {
                let value = param.value_basehost;
                if (param.value_basehost) {
                    if (param.value_name && param.value_name !== '@')
                        value = param.value_name.trim() + "." + value;
                }
                params[param.name] = value;
            } else if (param.type == 'namespace') {
                params[param.name] = this.project.id;
            } else {
                params[param.name] = param.value ? param.value : '';
            }
        }

        let pd = {
            project_id: this.project.id,
            deploy_id: this.selected.id,
            name: this.deploy_name,
            params: params
        };

        const { code, data } = await wiz.call("create", { data: JSON.stringify(pd) });
        await this.service.status.hide("loading");
        if (code != 200) {
            return await this.service.modal.error(data, false, `Confirm`);
        }
        await this.model.action();
    }

}

export default PortalInfraProjectDeployNewComponent;