import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.chartjs.pie');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ViewChild, ElementRef } from '@angular/core';
import { Chart } from 'chart.js/auto';

@Component({
    selector: 'wiz-portal-chartjs-pie',
template: templateSource || '',
    styles: [`

/* file: /opt/season-cloud-manager/project/main/build/src/app/portal.chartjs.pie/view.scss */
@charset "UTF-8";
canvas {
  width: 100% !important;
  /* 캔버스가 부모의 가로 크기를 따라감 */
  height: 100% !important;
  /* 캔버스가 부모의 높이를 따라감 */
}`],
})
export class PortalChartjsPieComponent implements OnInit {
    @ViewChild('canvas') canvasRef!: ElementRef<HTMLCanvasElement>;

    @Input() labels: any = ['Usage', 'Available'];
    @Input() backgroundColor: any = ['rgba(75, 192, 192, 1)', 'rgba(192, 192, 192, 0.5)'];
    @Input() data: any = [20, 100];

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.render();
        await this.createChart();
    }

    public async createChart() {
        new Chart(this.canvasRef.nativeElement, {
            type: 'pie',
            data: {
                labels: this.labels,
                datasets: [
                    {
                        data: this.data,
                        backgroundColor: this.backgroundColor
                    }
                ]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        });
    }
}

export default PortalChartjsPieComponent;