import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.infra.project.ip');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-infra-project-ip',
template: templateSource || '',
    styles: [`

/* file: /opt/season-cloud-manager/project/main/build/src/app/portal.infra.project.ip/view.scss */
/* src/app/portal.infra.project.ip/view.scss: no such file or directory */`],
})
export class PortalInfraProjectIpComponent implements OnInit {
    @Input() project: any;
    @Input() role: any = 'member';
    @Input() list: any = [];
    @Input() refresh: any;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.render();
    }

    public async create() {
        await wiz.call("create", { id: this.project.id });
        await this.refresh();
    }

    public async remove(item) {
        if (!await this.service.modal.error(`Are you sure delete '${item.ip}'?`, `Cancel`, `Delete`)) return;
        await wiz.call("remove", { id: this.project.id, ip: item.ip });
        await this.refresh();
    }

}

export default PortalInfraProjectIpComponent;