import { Inject } from '@angular/core';
import '@angular/compiler';
import { PortalDizestEditorVideoComponent } from './portal.dizest.editor.video/portal.dizest.editor.video.component';
import { PortalDizestEditorImageComponent } from './portal.dizest.editor.image/portal.dizest.editor.image.component';
import { PagePageAccessComponent } from './page.page.access/page.page.access.component';
import { PortalDizestEditorSettingComponent } from './portal.dizest.editor.setting/portal.dizest.editor.setting.component';
import { PageAdminUsersComponent } from './page.admin.users/page.admin.users.component';
import { PortalChartjsLineComponent } from './portal.chartjs.line/portal.chartjs.line.component';
import { PortalDizestEditorWorkflowSidebarCodeComponent } from './portal.dizest.editor.workflow.sidebar.code/portal.dizest.editor.workflow.sidebar.code.component';
import { PortalDizestEditorWorkflowSidebarPipComponent } from './portal.dizest.editor.workflow.sidebar.pip/portal.dizest.editor.workflow.sidebar.pip.component';
import { PortalInfraProjectMemberNewComponent } from './portal.infra.project.member.new/portal.infra.project.member.new.component';
import { ComponentNavSidebarComponent } from './component.nav.sidebar/component.nav.sidebar.component';
import { PageAdminDeployItemComponent } from './page.admin.deploy.item/page.admin.deploy.item.component';
import { PortalDizestEditorWorkflowSidebarApiComponent } from './portal.dizest.editor.workflow.sidebar.api/portal.dizest.editor.workflow.sidebar.api.component';
import { PortalSeasonModalComponent } from './portal.season.modal/portal.season.modal.component';
import { PortalChartjsPieComponent } from './portal.chartjs.pie/portal.chartjs.pie.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalDizestEditorWorkflowSidebarAiComponent } from './portal.dizest.editor.workflow.sidebar.ai/portal.dizest.editor.workflow.sidebar.ai.component';
import { PortalInfraProjectDeployComponent } from './portal.infra.project.deploy/portal.infra.project.deploy.component';
import { PortalSeasonTreeComponent } from './portal.season.tree/portal.season.tree.component';
import { PageProjectItemComponent } from './page.project.item/page.project.item.component';
import { PortalDizestEditorDefaultComponent } from './portal.dizest.editor.default/portal.dizest.editor.default.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalInfraProjectIpComponent } from './portal.infra.project.ip/portal.infra.project.ip.component';
import { PortalInfraProjectDeployEditComponent } from './portal.infra.project.deploy.edit/portal.infra.project.deploy.edit.component';
import { PortalInfraProjectMonitorComponent } from './portal.infra.project.monitor/portal.infra.project.monitor.component';
import { PortalSeasonLoadingDefaultComponent } from './portal.season.loading.default/portal.season.loading.default.component';
import { PortalDizestDriveComponent } from './portal.dizest.drive/portal.dizest.drive.component';
import { PortalDizestEditorTerminalComponent } from './portal.dizest.editor.terminal/portal.dizest.editor.terminal.component';
import { PageAdminDeployComponent } from './page.admin.deploy/page.admin.deploy.component';
import { PortalDizestEditorWorkflowSidebarAppsComponent } from './portal.dizest.editor.workflow.sidebar.apps/portal.dizest.editor.workflow.sidebar.apps.component';
import { PortalSeasonFormDropdownComponent } from './portal.season.form.dropdown/portal.season.form.dropdown.component';
import { PortalSeasonPaginationComponent } from './portal.season.pagination/portal.season.pagination.component';
import { PortalInfraProjectDeployNewComponent } from './portal.infra.project.deploy.new/portal.infra.project.deploy.new.component';
import { PortalDizestEditorHealthComponent } from './portal.dizest.editor.health/portal.dizest.editor.health.component';
import { PortalDizestEditorWorkflowNodeComponent } from './portal.dizest.editor.workflow.node/portal.dizest.editor.workflow.node.component';
import { PortalInfraProjectMemberComponent } from './portal.infra.project.member/portal.infra.project.member.component';
import { PortalDizestEditorWorkflowComponent } from './portal.dizest.editor.workflow/portal.dizest.editor.workflow.component';
import { PortalDizestEditorWorkflowSidebarInfoComponent } from './portal.dizest.editor.workflow.sidebar.info/portal.dizest.editor.workflow.sidebar.info.component';
import { PortalInfraProjectInfoComponent } from './portal.infra.project.info/portal.infra.project.info.component';
import { PageMainComponent } from './page.main/page.main.component';
import { LayoutSidebarComponent } from './layout.sidebar/layout.sidebar.component';
import { PageProjectComponent } from './page.project/page.project.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NuMonacoEditorModule } from '@ng-util/monaco-editor';
import { SortablejsModule } from "src/libs/portal/season/ngx-sortablejs";
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
    constructor(@Inject( DomSanitizer) private domSanitizer: DomSanitizer) { }
    transform(url) {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }
}

// translate libs
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClient, HttpClientModule } from '@angular/common/http';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/lang/', '.json');
}

// initialize ng module
@NgModule({
    declarations: [
        SafePipe,
        AppComponent,
        PortalDizestEditorVideoComponent,
        PortalDizestEditorImageComponent,
        PagePageAccessComponent,
        PortalDizestEditorSettingComponent,
        PageAdminUsersComponent,
        PortalChartjsLineComponent,
        PortalDizestEditorWorkflowSidebarCodeComponent,
        PortalDizestEditorWorkflowSidebarPipComponent,
        PortalInfraProjectMemberNewComponent,
        ComponentNavSidebarComponent,
        PageAdminDeployItemComponent,
        PortalDizestEditorWorkflowSidebarApiComponent,
        PortalSeasonModalComponent,
        PortalChartjsPieComponent,
        PortalSeasonLoadingSeasonComponent,
        PortalDizestEditorWorkflowSidebarAiComponent,
        PortalInfraProjectDeployComponent,
        PortalSeasonTreeComponent,
        PageProjectItemComponent,
        PortalDizestEditorDefaultComponent,
        LayoutEmptyComponent,
        PortalInfraProjectIpComponent,
        PortalInfraProjectDeployEditComponent,
        PortalInfraProjectMonitorComponent,
        PortalSeasonLoadingDefaultComponent,
        PortalDizestDriveComponent,
        PortalDizestEditorTerminalComponent,
        PageAdminDeployComponent,
        PortalDizestEditorWorkflowSidebarAppsComponent,
        PortalSeasonFormDropdownComponent,
        PortalSeasonPaginationComponent,
        PortalInfraProjectDeployNewComponent,
        PortalDizestEditorHealthComponent,
        PortalDizestEditorWorkflowNodeComponent,
        PortalInfraProjectMemberComponent,
        PortalDizestEditorWorkflowComponent,
        PortalDizestEditorWorkflowSidebarInfoComponent,
        PortalInfraProjectInfoComponent,
        PageMainComponent,
        LayoutSidebarComponent,
        PageProjectComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        SortablejsModule,
        KeyboardShortcutsModule.forRoot(),
        NuMonacoEditorModule.forRoot({ baseUrl: `lib` }),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            defaultLanguage: 'en'
        }),
        
    ],
    providers: [
        {
            provide: COMPOSITION_BUFFER_MODE,
            useValue: false
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }