import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.chartjs.line');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ViewChild, ElementRef } from '@angular/core';
import { Chart } from 'chart.js/auto';

@Component({
    selector: 'wiz-portal-chartjs-line',
template: templateSource || '',
    styles: [`

/* file: /opt/season-cloud-manager/project/main/build/src/app/portal.chartjs.line/view.scss */
/* src/app/portal.chartjs.line/view.scss: no such file or directory */`],
})
export class PortalChartjsLineComponent implements OnInit {
    @ViewChild('canvas') canvasRef!: ElementRef<HTMLCanvasElement>;

    @Input() label: any = "";
    @Input() borderColor: any = 'rgba(75, 192, 192, 1)';
    @Input() fill: boolean = false;
    @Input() yMax: any = null;

    @Input() data: any = [
        { time: "2024-11-05T00:00:00", value: 0.4147918860372756 },
        { time: "2024-11-05T01:00:00", value: 0.43156770609898604 },
        { time: "2024-11-05T02:00:00", value: 0.45417628845603714 },
        { time: "2024-11-05T03:00:00", value: 0.49550799314162663 },
        { time: "2024-11-05T04:00:00", value: 0.46598921520506825 },
        { time: "2024-11-05T05:00:00", value: 0.4770847842805672 },
        { time: "2024-11-05T06:00:00", value: 0.48182645096524435 },
        { time: "2024-11-05T07:00:00", value: 0.4863117887807706 },
        { time: "2024-11-05T08:00:00", value: 0.4964252552203558 },
        { time: "2024-11-05T09:00:00", value: 0.5032115371709213 },
        { time: "2024-11-05T10:00:00", value: 0.49814795229000974 },
        { time: "2024-11-05T11:00:00", value: 0.508130116891236 }
    ];

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.render();
        await this.createChart();
    }

    public async createChart() {
        const labels = this.data.map(data => new Date(data.time).format("MM/dd HH"));
        const data = this.data.map(data => data.value);

        this.chart = new Chart(this.canvasRef.nativeElement, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: this.label,
                        data: data,
                        borderColor: this.borderColor,
                        fill: this.fill,
                        pointRadius: 0,
                        tension: 0.4
                    }
                ]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    }
                },
                scales: {
                    x: {
                        type: 'category',
                        title: {
                            display: false
                        },
                        ticks: {
                            display: false
                        },
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        beginAtZero: true,
                        max: this.yMax,
                        title: {
                            display: this.label ? true : false,
                            text: this.label
                        }
                    }
                }
            }
        });
    }
}

export default PortalChartjsLineComponent;