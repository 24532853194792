import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.infra.project.deploy');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-infra-project-deploy',
template: templateSource || '',
    styles: [`

/* file: /opt/season-cloud-manager/project/main/build/src/app/portal.infra.project.deploy/view.scss */
/* src/app/portal.infra.project.deploy/view.scss: no such file or directory */`],
})
export class PortalInfraProjectDeployComponent implements OnInit {
    @Input() project: any;
    @Input() role: any = 'member';
    @Input() refresh: any;

    public list: any = [];
    public modal: any;
    public modalEdit: any;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        this.modal = this.service.modal.localize();
        this.modalEdit = this.service.modal.localize();
        await this.load();
    }

    public async load() {
        let { code, data } = await wiz.call("load", { id: this.project.id });
        this.list = data;
        this.service.status.hide("loading");
        await this.service.render();
    }

    public async create() {
        await this.modal.show();
        await this.load();
    }

    public async edit(item) {
        this.modalEdit.deploy = item;
        await this.modalEdit.show();
        await this.load();
    }

    public async stop(item) {
        if (!await this.service.modal.error(`Are you sure stop '${item.name}'?`, `Cancel`, `Stop`)) return;
        this.service.status.show("loading");
        await wiz.call("stop", { id: this.project.id, deploy_id: item.id });
        await this.load();
    }

    public async start(item) {
        this.service.status.show("loading");
        let { code, data } = await wiz.call("start", { id: this.project.id, deploy_id: item.id });
        if (code != 200) {
            this.service.status.hide("loading");
            await this.service.modal.error(data, false, `Confirm`);
        }
        await this.load();
    }

    public async remove(item) {
        if (!await this.service.modal.error(`Are you sure delete '${item.name}'?`, `Cancel`, `Delete`)) return;
        this.service.status.show("loading");
        await wiz.call("remove", { id: this.project.id, deploy_id: item.id });
        await this.load();
    }

}

export default PortalInfraProjectDeployComponent;