import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.page.access');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-page-access',
template: templateSource || '',
    styles: [``],
})
export class PagePageAccessComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        // await this.service.status.show('loading');
        let check = await this.service.auth.check();
        if (check) return location.href = "/";
        // location.href = "/auth/saml/login";
    }

    public view: string = 'login';

    public data: any = {
        email: '',
        password: ''
    };

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.modal.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async login() {
        let user = JSON.parse(JSON.stringify(this.data));
        if (!user.password)
            delete user.password;
        else
            user.password = this.service.auth.hash(user.password);

        let { code, data } = await wiz.call("login", user);

        if (code == 200) {
            location.href = "/";
            await this.service.render();
        } else {
            await this.alert(data, 'error');
        }
    }

}

export default PagePageAccessComponent;