import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.sidebar');
import { OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-layout-sidebar',
template: templateSource || '',
    styles: [`

/* file: /opt/season-cloud-manager/project/main/build/src/app/layout.sidebar/view.scss */
/* src/app/layout.sidebar/view.scss: no such file or directory */`],
})
export class LayoutSidebarComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    @HostListener('document:click')
    public clickout() {
        this.service.status.toggle('navbar', false);
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }
}

export default LayoutSidebarComponent;