import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.deploy.item');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-deploy-item',
template: templateSource || '',
    styles: [`

/* file: /opt/season-cloud-manager/project/main/build/src/app/page.admin.deploy.item/view.scss */
/* src/app/page.admin.deploy.item/view.scss: no such file or directory */`],
})
export class PageAdminDeployItemComponent implements OnInit {
    public updating: boolean = false;
    public id: string = '';
    public data: any = { params: [] };

    constructor(@Inject( Service) public service: Service) {
        this.id = WizRoute.segment.id;
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow.role("admin", "/");
        await this.load();
    }

    public async load() {
        const { data } = await wiz.call("get", { id: this.id });
        this.data = data;
        if (!this.data.params) this.data.params = [];
        await this.service.render();
    }

    public async updateIcon() {
        this.data.icon = await this.service.file.read({ type: 'image', accept: 'image/*', width: 64, quality: 1 });
        await this.service.render();
    }

    public async deleteIcon() {
        this.data.icon = "";
        await this.service.render();
    }

    public async addParams() {
        this.data.params.push({ name: "", type: "text", description: "" });
        await this.service.render();
    }

    public async deleteParams(item) {
        this.data.params.remove(item);
        await this.service.render();
    }

    public async update() {
        await this.service.render(this.updating = true);

        let pd = JSON.stringify(this.data);
        const { data } = await wiz.call("update", { data: pd });

        await this.service.sleep(1000);

        if (!data.id) {
            await this.service.render(this.updating = false);
            return;
        }

        if (this.id != data.id) {
            this.id = data.id;
            this.data = data;
            this.service.href("/admin/deploy/" + this.id);
        }

        await this.service.render(this.updating = false);
    }

}

export default PageAdminDeployItemComponent;