import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.infra.project.monitor');
import { OnInit, OnDestroy, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-infra-project-monitor',
template: templateSource || '',
    styles: [`

/* file: /opt/season-cloud-manager/project/main/build/src/app/portal.infra.project.monitor/view.scss */
/* src/app/portal.infra.project.monitor/view.scss: no such file or directory */`],
})
export class PortalInfraProjectMonitorComponent implements OnInit, OnDestroy {
    @Input() project: any;
    @Input() role: any = 'member';

    public intervalId: any;

    public data: any = {};

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        this.intervalId = setInterval(async () => {
            await this.load();
        }, 5000);
    }

    public async ngOnDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    public async load() {
        const { code, data } = await wiz.call("load", { id: this.project.id });
        this.data = data;
        await this.service.render();
    }

    public timeDifferenceFormat(targetDate) {
        if (targetDate.includes("Z")) {
            targetDate = new Date(targetDate);
        } else {
            targetDate = new Date(targetDate);
            targetDate = new Date(targetDate.getTime() - targetDate.getTimezoneOffset() * 60000)
        }
        const now = new Date();
        const diffInSeconds = Math.floor((now - targetDate) / 1000);

        const seconds = diffInSeconds % 60;
        const minutes = Math.floor((diffInSeconds / 60) % 60);
        const hours = Math.floor((diffInSeconds / 3600) % 24);
        const days = Math.floor((diffInSeconds / (3600 * 24)) % 365);
        const years = Math.floor(diffInSeconds / (3600 * 24 * 365));

        let result = [];

        if (years > 0) result.push(`${years}y`);
        if (days > 0) result.push(`${days}d`);
        if (hours > 0) result.push(`${hours}h`);
        if (minutes > 0) result.push(`${minutes}m`);
        if (seconds > 0) result.push(`${seconds}s`);

        return result.slice(0, 2).join(' ');
    }

}

export default PortalInfraProjectMonitorComponent;