import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [`

/* file: /opt/season-cloud-manager/project/main/build/src/app/page.main/view.scss */
/* src/app/page.main/view.scss: no such file or directory */`],
})
export class PageMainComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public cluster_status: any = {};
    public health_status: any = {};

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("/access");
        await this.service.render();
        await this.usage();
        await this.health();
    }

    public async usage() {
        const { data } = await wiz.call("usage");
        this.cluster_status = data.cluster_status;
        await this.service.render();
    }

    public async health() {
        const { data } = await wiz.call("health");
        this.health_status = data;
        await this.service.render();
    }

}

export default PageMainComponent;