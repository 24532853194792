import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.users');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-users',
template: templateSource || '',
    styles: [`

/* file: /opt/season-cloud-manager/project/main/build/src/app/page.admin.users/view.scss */
/* src/app/page.admin.users/view.scss: no such file or directory */`],
})
export class PageAdminUsersComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public loading: boolean = false;
    public list: any = [];

    public keyuptime: any = 0

    public search: any = {
        text: "",
        page: 1,
        dump: 40
    }

    public pagination: any = {
        end: -1,
        start: -1,
        current: 0
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow.role("admin", "/");
        await this.load();
    }

    public async load(page: number = 1) {
        if (this.loading) return;
        this.loading = true;
        this.pagination.current = page;
        this.search.page = page;
        this.list = [];
        await this.service.render();

        const { code, data } = await wiz.call("search", this.search);

        if (code == 200) {
            let { rows, lastpage } = data;
            const startpage = Math.floor((page - 1) / 10) * 10 + 1;
            this.list = rows;
            this.pagination.start = startpage;
            this.pagination.end = lastpage;
        }

        this.loading = false;
        await this.service.render();
    }

    public async searchAction() {
        let keyuptime: any = new Date().getTime();
        this.keyuptime = keyuptime;
        await this.service.render(500);
        if (this.keyuptime != keyuptime) return;
        await this.load();
    }

    public async setAdmin(item) {
        await wiz.call("set_admin", { id: item.id });
        await this.load(this.search.page);
    }

    public async unsetAdmin(item) {
        await wiz.call("unset_admin", { id: item.id });
        await this.load(this.search.page);
    }

}

export default PageAdminUsersComponent;