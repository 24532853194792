import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.infra.project.deploy.edit');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-infra-project-deploy-edit',
template: templateSource || '',
    styles: [`

/* file: /opt/season-cloud-manager/project/main/build/src/app/portal.infra.project.deploy.edit/view.scss */
/* src/app/portal.infra.project.deploy.edit/view.scss: no such file or directory */`],
})
export class PortalInfraProjectDeployEditComponent implements OnInit {
    @Input() model: any = null;
    @Input() project: any = {};

    public deploy_name: any = "";

    public selected: any;
    public hosts: any = [];
    public ips: any = [];
    public deploy: any = [];

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load() {
        this.deploy_name = this.model.deploy.name;

        const { data } = await wiz.call("info", { id: this.project.id, deploy_id: this.model.deploy.deploy_id });

        this.hosts = data.hosts;
        this.ips = data.ips;
        this.deploy = data.deploy;
        this.selected = data.ref;

        let orgparams = this.model.deploy.params;

        for (let i = 0; i < this.selected.params.length; i++) {
            let param = this.selected.params[i];

            if (orgparams[param.name]) {
                if (param.type == 'hostname') {
                    let ovalue = orgparams[param.name];
                    if (ovalue.split(".").length == 3) {
                        param.value_basehost = ovalue;
                        param.value_name = '';
                    } else if (ovalue.split(".").length == 4) {
                        param.value_basehost = ovalue.split('.').slice(1).join(".");
                        param.value_name = ovalue.split('.')[0];
                    } else {
                        param.value_name = '';
                        param.value_basehost = '';
                    }
                } else {
                    param.value = orgparams[param.name];
                }
            } else {
                if (param.type == 'hostname') {
                    param.value_basehost = '';
                } else {
                    param.value = "";
                }
            }
        }
        await this.service.render();
    }

    public async update() {
        if (!this.deploy_name)
            return await this.service.modal.error(`Input Deploy Name`, false, `Confirm`);

        await this.service.status.show("loading");
        let params = {};

        for (let i = 0; i < this.selected.params.length; i++) {
            let param = this.selected.params[i];
            if (param.type == 'hostname') {
                let value = param.value_basehost;
                if (param.value_basehost) {
                    if (param.value_name && param.value_name !== '@')
                        value = param.value_name.trim() + "." + value;
                }
                params[param.name] = value;
            } else if (param.type == 'namespace') {
                params[param.name] = this.project.id;
            } else {
                params[param.name] = param.value ? param.value : '';
            }
        }

        let pd = JSON.parse(JSON.stringify(this.model.deploy));
        pd.params = params;
        pd.name = this.deploy_name;

        const { code, data } = await wiz.call("update", { data: JSON.stringify(pd) });
        await this.service.status.hide("loading");
        if (code != 200) {
            return await this.service.modal.error(data, false, `Confirm`);
        }
        await this.model.action();
    }

}

export default PortalInfraProjectDeployEditComponent;