import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.project.item');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-project-item',
template: templateSource || '',
    styles: [`

/* file: /opt/season-cloud-manager/project/main/build/src/app/page.project.item/view.scss */
/* src/app/page.project.item/view.scss: no such file or directory */`],
})
export class PageProjectItemComponent implements OnInit {
    public updating: boolean = false;
    public id: string = '';
    public data: any = null;
    public hosts: any = [];
    public ip_list: any = [];
    public role: any = 'visitor';

    constructor(@Inject( Service) public service: Service) {
        this.id = WizRoute.segment.id;
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("/access");
        await this.load();
    }

    public async load() {
        this.data = null;
        await this.service.render();

        const { data } = await wiz.call("get", { id: this.id });
        this.data = data.project;
        this.role = data.role;
        this.ip_list = data.ip_list;
        this.hosts = data.hosts;
        if (!this.data.params) this.data.params = [];
        await this.service.render();
    }

    public tab: any = 'info';

    public tabClass(tab: string) {
        if (this.tab == tab)
            return 'cursor-pointer flex whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600';
        return 'cursor-pointer flex whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700';
    }

    public async switchTab(tab: string) {
        this.tab = tab;
        await this.service.render();
    }

    public async update() {
        await this.service.render(this.updating = true);

        let pd = JSON.stringify(this.data);
        const { data } = await wiz.call("update", { data: pd });

        await this.service.sleep(1000);

        if (!data.id) {
            await this.service.render(this.updating = false);
            return;
        }

        if (this.id != data.id) {
            this.id = data.id;
            this.data = data;
            this.service.href("/project/" + this.id);
        }

        await this.service.render(this.updating = false);
    }

    public grafanaUrl() {
        return 'https://grafana.infra.season.co.kr/d/85a562078cdf77779eaa1add43ccec1e/mixin-compute-resources-namespace-pods?orgId=1&refresh=10s&var-datasource=default&var-cluster=&var-namespace=' + this.data.id + '&theme=light&from=now-2d&var-resolution=5m&kiosk';
    }

}

export default PageProjectItemComponent;