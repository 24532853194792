import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.infra.project.member');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-infra-project-member',
template: templateSource || '',
    styles: [`

/* file: /opt/season-cloud-manager/project/main/build/src/app/portal.infra.project.member/view.scss */
/* src/app/portal.infra.project.member/view.scss: no such file or directory */`],
})
export class PortalInfraProjectMemberComponent implements OnInit {
    @Input() project: any;
    @Input() role: any = 'member';

    public list: any = [];
    public modal: any;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        this.modal = this.service.modal.localize();
        await this.load();
    }

    public async create() {
        await this.modal.show();
        await this.load();
    }

    public async remove(item) {
        if (this.role != 'admin') return;
        if (!await this.service.modal.error(`Are you sure delete '${item.name}'?`, `Cancel`, `Delete`)) return;
        await wiz.call("remove", { id: this.project.id, user_id: item.id });
        await this.load();
    }

    public async load() {
        const { code, data } = await wiz.call("load", { id: this.project.id });
        this.list = data;
        await this.service.render();
    }

    public async setAdmin(item) {
        if (this.role != 'admin') return;
        await wiz.call("update", { id: this.project.id, user_id: item.id, role: 'admin' });
        await this.load();
    }

    public async unsetAdmin(item) {
        if (this.role != 'admin') return;
        await wiz.call("update", { id: this.project.id, user_id: item.id, role: 'member' });
        await this.load();
    }

}

export default PortalInfraProjectMemberComponent;