import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.infra.project.info');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-infra-project-info',
template: templateSource || '',
    styles: [`

/* file: /opt/season-cloud-manager/project/main/build/src/app/portal.infra.project.info/view.scss */
/* src/app/portal.infra.project.info/view.scss: no such file or directory */`],
})
export class PortalInfraProjectInfoComponent implements OnInit {
    @Input() id: any;
    @Input() role: any;
    @Input() data: any = {};
    @Input() hosts: any = [];
    @Input() refresh: any;

    public deleteString: any = false;
    public zones: any = [];

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load() {
        const { code, data } = await wiz.call("zone", { id: this.data.id });
        this.zones = data;
        await this.service.render();
    }

    public async updateIcon() {
        this.data.icon = await this.service.file.read({ type: 'image', accept: 'image/*', width: 64, quality: 1 });
        await this.service.render();
    }

    public async deleteIcon() {
        this.data.icon = "";
        await this.service.render();
    }

    public async deleteProject() {
        if (this.deleteString) {
            if (this.data.name == this.deleteString) {
                if (!await this.service.modal.error(`Are you sure delete '${this.data.name}'?`, `Cancel`, `Delete`)) return;
                await wiz.call("delete", { id: this.data.id });
                await this.service.href("/project");
            } else {
                await this.service.modal.error(`Input valid project name`, `Cancel`, `Confirm`)
            }
        }

        this.deleteString = "";
        await this.service.render();
    }

    public newdns: any = { name: "", basehost: "dizest.net" };

    public async addDNS() {
        this.newdns.id = this.data.id;
        const { code, data } = await wiz.call("add_dns", this.newdns);
        if (code == 200) {
            await this.refresh();
            this.newdns = { name: "", basehost: "dizest.net" };
        } else {
            await this.service.modal.error(data);
        }
        await this.service.render();
    }

    public async deleteDNS(item) {
        if (!await this.service.modal.error(`Are you sure delete '${item.name}.${item.basehost}'?`, `Cancel`, `Delete`)) return;
        item.id = this.data.id;
        const { code, data } = await wiz.call("delete_dns", item);
        if (code == 200) {
            await this.refresh();
        } else {
            await this.service.modal.error(data);
        }
        await this.service.render();
    }
}

export default PortalInfraProjectInfoComponent;